<template>
    <div id="system-setting">
        系统配置
    </div>
</template>

<script>
export default {
    name: "SystemSetting", // 系统配置
}
</script>

<style lang="less" scoped>
</style>